export const warehouseStatusHelper = {
    getStyle
};

function getStyle(warehouseStatus) {
    let style = ""
    if (!warehouseStatus) {
        return style
    }

    let colorRGB = hexToRGB(warehouseStatus.frontendHexColor);
    let adjustedBrightness = 1.6;
    let brighterColor = `rgb(${colorRGB.r * adjustedBrightness}, ${colorRGB.g * adjustedBrightness}, ${colorRGB.b * adjustedBrightness})`;

    style += `background-color: ${hexToRGBA(warehouseStatus.frontendHexColor, 0.25)} !important;`
    style += `color: ${brighterColor} !important;`

    return style
}

function hexToRGBA(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function hexToRGB(hex) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    return { r, g, b };
}